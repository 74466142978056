import * as React from "react";
import { graphql, PageProps } from "gatsby";

import Layout from "../../components/layout/layout";
import SEO from "../../components/seo";
import { DefiCoinsQuery } from "../../../graphql-types";
import Coins from "../../components/pages/coins/coins";
import { NavTab } from "../../components/layout/navigation/navigation";

type DefiCoinsPageProps = PageProps<DefiCoinsQuery>;

const DefiCoinsPage: React.FC<DefiCoinsPageProps> = ({ data }) => {
  return (
    <Layout activeTab={NavTab.Coins}>
      <SEO
        title="Top DeFi tokens by market capitalization"
        description="Real-time market insights on DeFi tokens prices, market cap, ranking and details of each token."
      />

      <Coins staticData={data} isDefi={true} />
    </Layout>
  );
};

export default DefiCoinsPage;

export const query = graphql`
  query DefiCoins {
    allCoinListItem(limit: 100, filter: { is_defi: { eq: true } }) {
      nodes {
        id
        symbol
        name
        image
        current_price
        market_cap
        market_cap_rank
        price_change_percentage_24h
        price_change_percentage_24h_in_currency
        price_change_percentage_7d_in_currency
      }
    }
  }
`;
