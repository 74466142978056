import * as React from "react";
import { navigate } from "gatsby";
import { CoinListItemResult } from "../../../../types/api-results/coin-list-item";
import { EventCategory } from "../../../utils/analytics";
import Container from "../../ui-library/container/container";
import ItemPicker from "../../ui-library/item-picker/item-picker";
import { SiteContext } from "../../../site-context/site-context";
import Search from "../../search/search";
import MarginBox from "../../ui-library/margin-box/margin-box";
import { grid } from "../../ui-library/design-tokens/grid";
import PageHeading from "../../ui-library/page-heading/page-heading";
import { useMarketGlobal } from "../../../hooks/query-hooks/use-market-global";
import { useDefiGlobal } from "../../../hooks/query-hooks/use-defi-global";
import { formatCurrency } from "../../../utils/format-number";
import styles from "./coins.styles";
import CoinListTable from "../../shared/coin-list-table/coin-list-table";

interface CoinsProps {
  isDefi?: boolean;
  staticData: {
    allCoinListItem?: {
      nodes: CoinListItemResult[];
    };
  };
}

const Coins: React.FC<CoinsProps> = ({ isDefi, staticData }) => {
  const {
    isMobile,
    usdExchangeRate,
    currency: [currency],
  } = React.useContext(SiteContext);

  const { data: marketData } = useMarketGlobal();
  const { data: defiMarketData } = useDefiGlobal();

  const marketCap = marketData?.totalMarketCap?.[currency];
  const defiMarketCap = defiMarketData?.defiMarketCap
    ? defiMarketData.defiMarketCap * (usdExchangeRate || 1)
    : undefined;

  return (
    <>
      <Container>
        <PageHeading
          heading={
            !isDefi ? "Top Coins by Market Cap" : "DeFi Tokens by Market Cap"
          }
          subHeading={
            !isDefi
              ? `Crypto Market Cap is ${
                  formatCurrency({ value: marketCap, currency }) || "-"
                }`
              : `DeFi crypto market cap is ${
                  formatCurrency({ value: defiMarketCap, currency }) || "-"
                }`
          }
        />

        <MarginBox
          css={styles.pageNav}
          margin={{ desktop: { bottom: grid(4) }, mobile: { bottom: grid(3) } }}
        >
          <ItemPicker
            itemPickerName="marketsType"
            activeItemValue={isDefi ? "defi" : "all"}
            items={[
              { label: isMobile ? "All" : "All Markets", value: "all" },
              { label: isMobile ? "DeFi" : "DeFi Market", value: "defi" },
            ]}
            isUniWidth={true}
            onItemClick={() => navigate(isDefi ? "/coins/" : "/coins/defi/")}
            eventCategory={EventCategory.CoinList}
          />

          <Search />
        </MarginBox>
      </Container>

      <Container fullWidthOnMobile={true}>
        <CoinListTable
          isDefi={isDefi}
          staticData={staticData.allCoinListItem?.nodes}
        />
      </Container>
    </>
  );
};

export default Coins;
